
/*   -=========== SendBasket ===========-   */
.order-page{
    &--message-manager {
      white-space: normal;
      width: calc(100% - 150px);
      font-size: 20px;
      font-style: italic;
    }

    &--wraper-send-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--wrapper-price {
      padding: 20px 22px;
      background-color: #FFFFFF;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      @media $mobile {
        background-color: #fff !important; 
        border: none !important;
        padding: 0 30px !important;
      }

    }

    &--button-submit {
      .submit-button {

        &::before {
          content: url('/i/check-button.svg');
          vertical-align: center;
          margin-right: 20px;
        }
      
        @media $mobile {
          &::before {
            content: none;
          }
          height: auto;
          padding: 14px 10px;
        }
      
      }
      @media $mobile {
        margin-top: 16px;
      }
    }

    &--information-order {
      white-space: normal;
      text-align: center;
      margin-top: 0;
      font-size: 24px;
      @media $mobile {
        margin-top: 20px;
      }
    }
}
.order-page--send {
  margin-top: 50px;
  margin-left: 65px;

  &-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding: 5px 0 5px 20px;
    height: 40px;
    max-width 365px
    border: 1px solid #97cdfa;
    font-size: 1.8rem;
    white-space: nowrap;
    color: #000;
    cursor: pointer;
    border-radius: 30px;

  }
  &-basket{
    display: block;
    margin-right: 16px;
    width: 30px;
    height: 36px;
    background: url(/i/podelitsya-oboima.png) no-repeat center center/contain #fff;
  }
  &-email{
    display: block;
    width: 46px;
    height: 26px;
    background: url(/i/mail-oboima.png) no-repeat center center/contain #fff;
    transform: translate(-12px ,0px);
  }
  &-print{
    display: block;
    margin-right: 10px;
    width: 39px;
    height: 27px;
    background: url(/i/print-oboima.png) no-repeat center center/contain #fff;
    transform: translate(-5px ,0px);
  }
  &-mouse-basket {
    background: url("/i/mouse-basket.svg") no-repeat;
    width: 115px;
    height: 85px;
    display: inline-block;
    background-size: contain;
    
    @media $mobile {
      background: url("/i/basket-bag.svg") no-repeat;
      background-size: contain;
      height: 130px;  
    }
  }


  @media $mobile {
    margin: 0;
    &-block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      height: 40px;
      border: 1px solid #bdbdbd;
      font-size: 1.8rem;
      text-align: center;
      white-space: nowrap;
      color: #000;
    }
    &-basket {
      display: block;
      margin-right: 16px;
      width: 30px;
      height: 36px;
    }
    &-email {
      display: block;
      margin-right: 0;
      width: 46px;
      height: 26px;
    }
    & > .send-block--print {
      display: none;
    }
  }
}

@media $small-mobile{
  .order-page--send-block {
    padding: 0;
    max-width: unset;
    width: unset;
    font-size: 1.6rem;
    font-weight 300;
  }
}
@media $small-mobile{
  .order-page--form-wrap {
    width: 100%;
  }
}


.order-page--send.first-step{
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  & > .order-page--send-block{
    justify-content: center;
    padding: 5px 10px 5px 20px;
    width: 100%;
    margin: 0;
    &:not(:last-child){
    }
  }

  @media $mobile {
    & {
      align-items: center;
      flex-direction: column;
    }
    & > .order-page--send-block:not(:last-child){
      padding: 0;
      width: none;
      border: 1px solid #bdbdbd;
      font-weight: 300;
    }
  }

  @media $small-mobile {
    & {
      align-items: center;
      flex-direction: column;
    }
    & > .order-page--send-block:not(:last-child){
        border: 1px solid #bdbdbd;
    }
  }
}
#send-basket--popup-background {
  display:none;
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 999998;
}
.send-basket--popup {
  display: none;

  &-block{
    position: fixed;
    top: 20%;
    left: 50%;
    padding: 50px;
    width: 360px;
    margin-left: -200px;
    background: #fff;
    border: 1px solid orange;
    border-radius: 4px;
    z-index: 999999;
    opacity: 1;
    & > .product-card--sharing{
      position:  unset;
      margin-top: 10px;
      padding: 0 15px;
      transform: unset;
      //& > .ya-share2 { padding: 0 10px;}
    }
    & > .product-card--sharing li{
      margin-left: 0;
    }
    @media $mobile {
      & {
        padding: 15px;
      }
    }
    @media $small-mobile{
      & {
        margin-left: -155px;
        width: unset;
      }
    }
  }
  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &-title{
    padding-bottom: 29px;
    @media $mobile {
      & {
        padding-top: 20px;
      }
    }
  }
  &-copy{
    width: 100%;
    padding: 10px;
    //border-radius: 30px;
    background-color: #97cdfa;
    color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,0.25);
    &:hover{
      background-color: #5ca5e1;
      color: #fff;
    }
  }
  &-textarea{
    margin-top: 8px;
    padding: 5px 0 10px 0;
    width: 100%;
    height: 30px;
    border: 1px solid #bdbdbd;
    white-space: nowrap;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    resize: none;
  }
  &-link {
    display: none;
    padding-top: 20px;
    width: 100%;
  }
}

.product--remove-button {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  width: 38px;
  height: 35px;
  background: url("/i/trash-mobile.svg") no-repeat;
  transform: none;

  @media $small-mobile {
    background: $red;
    border-radius: 50%;
    position: absolute;
    right: -5px; top: -15px;

    &:before, &:after {
      background: $white;
    }
  }
}

.send-basket--popup-form{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  & > input[type=email]{
    margin-bottom: 10px;
    padding: 5px 0 5px 0;
    border: 1px solid #bdbdbd;
  }
  & > button[type=submit]{
    padding: 10px;
    background-color: #97cdfa;
    color: #fff;
    box-shadow: 0 4px 20px rgba(0,0,0,0.25);
    &:hover{
      background-color: #5ca5e1;
      color: #fff;
    }
  }
}
input[name="copyemail"] {
  display: block;
  width: 2px;
  height: 2px;
  margin-bottom: -2px;
  border: none;
  padding: 0;
  opacity: 0.01;
}

.send-basket--popup-block {
  & > .send-basket--popup-sharing {
    padding-top: 20px;
  }
  & > .product-card--sharing {
    border-radius: 0;
  }
}