.main-popup {
  margin-top 20px;

  &__text-red {
    color #E72749;
  }

  &__header {
    display flex;
    justify-content space-between;
    margin-bottom 15px;

    &--text {
      width 520px;
    }

    &--title {
      font-weight 400;
      font-size 46px;
      line-height 60px;
      margin-bottom 15px;
    }

    &--delivery {
      width 470px;
      font-size 16px;
      line-height 24px;
    }
  }

  &__animation {
    display: flex;
    align-items: flex-end;

    &--point {
      position absolute;
      top 0;
      left 20px;
    }
  }

  &__summary {
    padding 22px 60px;
    background linear-gradient(180deg, #F7FBFF 0%, #FFFFFF 100%);
    border-radius 30px;
    display flex;
    justify-content space-between;
    font-size: 16px;
    line-height: 24px;
    margin-bottom 15px;

    &--item {
      display flex;
      flex-direction column;
      align-items center;
      width 180px;
      text-decoration none;

      &:hover {
        .main-popup__summary--title {
          color #e75671;
        }

        .main-popup__summary--text {
          color #7a7a7a;
        }
      }
    }

    &--img {
      margin-bottom 15px;
    }

    &--roll {
      width 53px;
      height 57px;
    }

    &--stars {
      width 118px;
      height 33px;
    }

    &--glue {
      width 139px;
      height 61px;
    }

    &--text {
      text-align center;
    }

    &--title {
      text-decoration underline;
    }
}

  &__request {
    display flex;
    border: 1px solid #97CDFA;
    border-radius: 30px;
    padding 15px 35px;
    margin-bottom 30px;
  }

  &__notice {
    min-width 380px;
  }

  &__sub-header {
    font-size 30px;
    line-height 39px;
    width 300px;
    margin-bottom 15px;
  }

  &__list {
    font-size 16px;
    line-height 24px;

    &--item {
      display flex;
      margin-bottom 15px;
      align-items center;
    }

    &--item-mobile {
      display none;
    }

    &--item-desktop {
      display flex;
    }

    &--img {
      margin-right 15px;
    }
  }

  &__summary-mobile {
    display none;
  }

  &__form {
    font-size: 16px;
    line-height: 21px;
    width 100%;

    .errorMessage {
      font-size: 1.5rem;
      line-height: 1.1;
      color: #eb5757;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  &__input {
    border 1px solid #97CDFA;
    border-radius 5px;
    padding 7px 12px;
    width 100%;
    margin-bottom 10px;
    box-sizing border-box;
  }

  &__button {
    width 100%;
    padding 13px;
    background: #97CDFA;
    border-radius: 5px;
    color white;

    &--icon {
      display none;
    }
  }

  &__logo {
    margin-bottom 15px;

    &--img-mobile {
      display none;
    }
  }

  &__postscript {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .main-popup {
    &__animation {
      display none;
    }

    &__header {
      &--title {
        font-size 28px;
        line-height 37px;
        margin-bottom 0;
      }

      &--delivery {
        display none;
      }
    }

    &__summary {
      display none;
    }

    &__request {
      flex-direction column-reverse;
      border 0;
      padding 0;
    }

    &__notice {
      min-width 0;
    }

    &__sub-header {
      font-size 24px;
      line-height 31px;
      text-align center;
      width auto;
    }

    &__list {
      &--item {
        margin-bottom 5px;
      }

      &--item-mobile {
        display flex;
      }

      &--item-desktop {
        display none;
      }
    }

    &__summary-mobile {
      display block;
      font-size 14px;
      line-height 18px;
      margin-bottom 25px;

      &--item {
        display flex;
        margin-bottom 5px;
        align-items center;
        background: linear-gradient(90deg, #FFFFFF 0%, #E4F5FF 50%);
        padding-top 5px;
        padding-bottom 5px;
        border-radius 5px;
      }

      &--img {
        min-width: 57px;
        text-align: center;
        margin-right 15px;
      }
    }

    &__form {
      margin-bottom 15px;
    }

    &__request {
      margin-bottom 10px;
    }

    &__button {
      &--text {
        margin-right 5px;
      }

      &--icon {
        display inline-block;
      }
    }

    &__logo {
      &--img-mobile {
        display block;
        max-width: calc(100% + 30px);
        margin-left: -15px;
      }

      &--img {
        display none;
      }
    }
  }
}

a.main-popup__summary-mobile--item {
  text-decoration none;

  span {
    text-decoration underline;
  }
}