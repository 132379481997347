/*   -=========== Contact ===========-   */

.contact-page {
  color: black;
  margin: 0 10px;
}

.contact-page--caption {
  color: #000;
  text-align: center;
  position: relative;
  margin-bottom: 35px;

  @media $mobile {
    margin-bottom: 15px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #828282;
    position: absolute;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
  }

  .h1 {
    display: inline-block;
    background: $white;
    padding: 0 15px;
    position: relative;
  }
}


.contact-page--map {
  position: relative;

  .map {
    height: 470px;
  }
}


.contact-page--info {
  background: rgba($white, .8);
  padding: 30px 40px 30px 45px;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.5;

  @media $bigger-than-wrapper {
    left: 'calc(50vw - %s / 2)' % $wrapper-width;
  }

  @media $not-small-handheld {
    width: 366px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media $less-than-wrapper {
      left: 50px;
    }
  }

  @media $small-handheld {
    padding: 20px;
  }
}

$contact-icons-padding = 36px;

.contact-page--group_phones {
  font-size: 2.4rem;
  line-height: 1.2;
  padding-left: $contact-icons-padding;
  background: url(/i/phone-icon.svg) no-repeat;
}

.contact-page--group_messengers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 210px;
  margin-left: 15px;
  margin-top: 18px;

  .item {
    display: block;
  }

  for $messenger in 'whatsapp' 'viber' 'telegram' {
    .item_{$messenger} {
      width: width('i/' + $messenger + '.svg');
      height: height('i/' + $messenger + '.svg');
      background: url('/i/img/social/' $messenger '.png') no-repeat 50% / contain;
    }
  }
}

.contact-page--group_address {
  padding-left: $contact-icons-padding;
  background: url(/i/pin-icon.svg) no-repeat;
  margin-top: 15px;
}

.contact-page--workhours {
  padding-left: $contact-icons-padding;
  background: url(/i/time-icon-blue.svg) no-repeat;
  margin-top: 1em;

  .item + .item {
    margin-top: 1em;
  }
}

.contact-page--scheme-link {
  @extend .btn, .blue-btn, .rounded-btn;
  height: 40px;
  line-height: 36px;
  padding: 0 42px 0 15px;
  font-size: 1.6rem;
  box-shadow: 0 4px 20px rgba(#000, .25);
  margin-top: 15px;
  margin-left: $contact-icons-padding;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 15px;
    background: url(/i/arrow-down.svg) no-repeat 50%;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
}


.contact-page--requisites {
  padding-top: 60px;
  padding-bottom: 30px;
}

.contact-page--requisites-caption {
  margin-bottom: 30px;
}

.contact-page--requisites-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact-page--requisites-text {
  font-size: 1.8rem;
  line-height: 1.35;

  @media $not-mobile {
    padding-right: 20px;
  }

  > * + * {
    margin-top: 2em;
  }

  li {
    list-style: none;
    padding-left: 36px;
    background: url(/i/mark-icon-dark.svg) no-repeat 0 .25em;
  }

  li + li {
    margin-top: 2.5em;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 63px;
      height: 2px;
      background: #bdbdbd;
      position: absolute;
      left: 36px;
      top: -1.5em;
    }
  }
}

.showroom-page--requisites-text {
  li + li {
    &:before {
      display: none;
    }
  }
}


.showroom-page--image {
  width 630px;
  margin-bottom: 30px;
}

.showroom-page--image-box {
  display flex;
  flex-wrap wrap;
  justify-content space-around;
}

.scheme-section {
  padding-top: 35px;

  .print-btn {
    @extend .btn, .rounded-btn;
    bordered-btn(1px, #828282);
    background: $white;
    height: 43px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 1.6rem;
    margin-left: 30px;
    margin-top: -5px;
    vertical-align: middle;

    &:hover {
      background: #828282;
      color: $white;
    }
  }
}

.scheme-section--content {
  background: #e0e2e5;
}

.scheme-section--caption {
  margin-bottom: 35px;
}

.designers-section--link {
  pointer-events: none; /* делаем ссылку некликабельной */
  cursor: default;
  text-decoration: none;

  @media $mobile {
    pointer-events: auto;
    cursor: pointer;
    text-decoration: underline;
  }
}

.contact-page--main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 18px;

  @media $mobile {
    grid-template-columns: 1fr;
  }
}

.contact-page--desc-wrapper {
  position: relative;
  margin-top: 20px;
  border: 1px solid #D7EDFF;
  border-radius: 15px;
  padding: 30px 18px;
  font-size: 20px;
}

.contact-page--desc--path {
  padding: 30px 18px 18px 5px;

  .contact-page--desc {
    grid-template-columns: 64px 1fr;
    row-gap: 30px;
  }

  .contact-page--desc-icon {
    width: 64px;
    height: 64px;
  }

  @media $mobile {
    padding: 5px;
  }
}

.contact-page--desc--requisites {
  @media $mobile {
    padding: 5px;
    font-size: 18px;
  }
}

.contact-page--desc-title {
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 14px;

  @media $mobile {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.contact-page--desc-top-image {
  position: absolute;
  width: 55px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -28px;
}

.contact-page--desc-icon {
  width: 40px;
  align-self: flex-start;
  height: 40px;
  object-fit: scale-down;
}

.contact-page--popup-button {
  border: 2px solid #ABD9FF;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 #abd9ff;
  height: 40px;
  background-color: white;
  color: #369DF2;
}

.contact-page--desc {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  column-gap: 18px;
  row-gap: 5px;
  font-size: 20px;
}

.contact-page--toggle-button {
  position: relative;
  width: 37px;
  border: 1px solid #87C8FF;
  border-radius: 20px;
  aspect-ratio: 1;

  &.open {
    &:after {
      -webkit-transform: rotate(135deg) translateY(-3px) translateX(3px);
      transform: rotate(135deg) translateY(-5px) translateX(5px);
    }
  }

  &:after {
    content: '';
    transform-origin: center;
    display: block;
    width: 13px;
    height: 13px;
    left: 12px;
    top: 9px;
    border-left: 1px solid #87c8ff;
    border-bottom: 1px solid #87c8ff;
    position: absolute;
    //top: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;

  }
}

.contact-page--desc-title-mobile {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}

.contact-page--desc-icon-mobile {
  width: 64px;
  aspect-ratio: 1;
}

.contact-page--swap-desc-button-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 15px;

  &.center:after {
    left: calc(50% - 32px);
  }

  &.right:after {
    left: calc(100% - 64px);
  }

  &:after {
    content: '';
    width: 64px;
    height: 3px;
    background: #87C8FF;
    position: absolute;
    bottom: -4px;
    transition: all 0.5s;
    left: 0px;
  }
}

.contact-page--desc--path-swappable-wrapper {
  width: 95%;
  margin: auto;
}

.contact-page--swap-desc-button {
  width: 64px;
}

.contact-page--swappable-desc-wrapper {
  display: grid;
  margin: 0 0 10px 0;
}

.contact-page--swappable-desc {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  background-clip: padding-box;

  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;

  margin-left: -5px;
  margin-right: -5px;

  &.contact-page--swappable-desc-left {
    background-image: url("/i/img/contacts/mobile/left.svg");
  }

  &.contact-page--swappable-desc-center {
    background-image: url("/i/img/contacts/mobile/center.svg");
  }

  &.contact-page--swappable-desc-right {
    background-image: url("/i/img/contacts/mobile/right.svg");
  }
}

.contact-page--swappable-desc {
  grid-area: 1 / 1 / 2 / 2;
  font-size: 18px;
}

#contact-page--popup {
  box-sizing: border-box;
  max-width: 95%;
  border-radius: 15px;
  padding: 25px;
  font-size: 22px;

  & .popup--close.js-close-popup {
    background: #E7E7E7;
    border: none;
    width: 50px;
    height: 50px;

    &:after {
      background: white;
    }

    &:before {
      background: white;
    }
  }

  & .errorMessage {
    margin: 0;
  }

  .contact-page--form-submit-button {
    background-color: #97CDFA;
    border-radius: 5px;
    font-size: 22px;
    color: white;
    margin: auto 0;
    height: 2em;
  }

  @media $small-handheld {
    padding: 15px 15px 25px;
    font-size: 20px;
  }

  input, textarea {
    background: #F8F8F8;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    padding: 0.33em 15px;
  }
}

.small-fields-wrapper {
  display: flex;
  gap: 13px;
  margin-bottom: 13px;
  justify-content: space-between;

  @media $mobile {
    flex-direction: column;
  }
}

.contact-page--popup-image {
  width: 64px;
  height: 64px;
}

.contact-page--popup-header {
  display: flex;
  gap: 18px;
  margin-bottom: 18px;

  @media $mobile {
    flex-direction: column;
  }
}

.contact-page--popup-fields-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 13px;
  column-gap: 13px;

  @media $mobile {
    grid-template-columns: 1fr;
  }
}

.contact-page--popup-soglashenie-text {
  grid-column: 1 / 3;

  @media $mobile {
    grid-column: 1;
  }
}

.contact-page--popup-content-field {
  grid-column: 1 / 4;

  @media $mobile {
    grid-column: 1;
  }
}

.contact-page--popup-header-text {
  @media $not-small-handheld {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.contact-page--desc--requisites-text-container {
  @media $mobile {
    display: none;
    margin: 0 20px 15px;
  }
}

.contact-page--advantages-header {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin: 20px;
  position: relative;

  & .h1 {
    position: relative;
    background: #fff;
    display: inline-block;
    padding: 0 20px;
    font-size: 26px;
  }

  @media $not-mobile {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #828282;
      position: absolute;
      left: 0;
      top: 60%;
      transform: translateY(-50%);
    }
  }

  @media $mobile {
    font-size: 26px;
  }
}


.contact-page--showroom {
  &-title {
    order: 1;
  }

  &-desc {
    order: 4;
  }
}

.contact-page--path {
  &-title {
    order: 2;
  }

  &-desc {
    order: 5;
  }
}

.contact-page--map {
  &-title {
    order: 3;
  }

  &-desc {
    order: 6;
  }
}

@media $mobile {
  .contact-page--showroom-desc {
    order: 2;
  }
  .contact-page--path-title {
    order: 3;
  }
  .contact-page--path-desc {
    order: 4;
  }
}

.contact-page--main-grid-title {
  display: block;
  text-align: center;
  font-size: 22px;
  padding: 15px 0;

  @media $mobild {
    font-size: 22px;
  }
}

.contact-page--contacts-desc {
  & p {
    margin: 10px 0;

    &.contact-page--contacts-desc-gap {
      margin-bottom: 20px;
    }
  }
}

.contact-page--advantages-container {
  margin: 40px 0;

  @media $mobile {
    margin: 28px 0;
  }
}

.contact-page--info-descriptions {
  height: 270px;
  min-height: 270px;
  max-height: 270px;

  @media $mobile {
    height: auto;
    max-height: none;
  }
}