
/*   -=========== Profile ===========-   */

.profile-page--caption {
  text-align: center;
  position: relative;
  margin-bottom: 65px;
  color: #000;

  @media only screen and (max-width: 1100px) {
    margin-bottom: 35px;
  }

  @media $small-mobile {
    margin-bottom: 15px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%; height: 1px;
    background: #828282;
    position: absolute;
    left: 0; top: 60%;
    transform: translateY(-50%);
  }

  .h1 {
    display: inline-block;
    background: $white;
    padding: 0 15px;
    position: relative;
  }
}

.profile-page--back-link {
  @extend .btn;
  bordered-btn(1px, #bdbdbd);
  padding-left: 35px;
  padding-right: 25px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 1.6rem;
  height: 40px;
  line-height: 38px;
  box-sizing: border-box;
  position: absolute;
  left: 0; bottom: 100%;
  z-index: 10;
  margin-bottom: 20px;
  white-space: nowrap;

  @media $mobile {
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 8px; height: 8px;
    border-top: 2px solid #828282;
    border-left: 2px solid #828282;
    border-radius: 1px;
    position: absolute;
    left: 18px; top: 50%;
    transform: rotate(-45deg) translateY(-50%);
    margin-top: -1px;
  }
}

.profile-page--container {
  position: relative;

  .wrapper {
    position: relative;
    z-index: 10;
  }

  @media $not-small-mobile {
    margin-bottom: 60px;
    min-height: 380px;
  }

  @media $small-mobile {
    margin-bottom: 30px;
  }
}

.profile-page--container_with-chat {
  min-height: 720px;
  margin-bottom: 0;

  /* @media $mobile {
    margin-bottom: 0;
  } */
}

$profile-aside-width = 380px;

.profile-page--content {
  position: relative;

  @media only screen and (min-width: 1101px) {
    &.profile-page--content_orders-list {
      padding-right: 'calc(%s - (100vw - %s) / 2 + 40px)' % ($profile-aside-width $wrapper-width);
    }
  }
}

.profile-page--content > .invoice-text {
  line-height: 2;

  .mobile-br {
    display: none;
  }

  @media screen and (max-width: 1101px) {
    line-height: 1;

    .mobile-text {
      display: none;
    }

    .mobile-br {
      display: inline;
    }
  }
}

.profile-page--aside {
  box-sizing: border-box;
  padding: 30px 40px 40px;
  box-shadow: 0 4px 20px rgba(#000, .1);
  font-size: 1.6rem;
  line-height: 1.5;

  @media only screen and (min-width: 1101px) {
    width: $profile-aside-width;
    position: absolute;
    right: 0; top: 0;
    z-index: 20;
  }

  @media only screen and (max-width: 1100px) {
    margin-bottom: 25px;
    margin-left: 20px;
    margin-right: 20px;
  }

  @media $small-mobile {
    padding: 20px 25px 25px;
    font-size: 1.4rem;
  }

  p + p {
    margin-top: .25em;
  }
}

.profile-page--aside-email {
  text-decoration: underline;
}

.profile-page--aside-checkbox {
  margin-top: .75em;
}

.profile-page--aside-group + .profile-page--aside-group {
  margin-top: 2em;

  @media $small-mobile {
    margin-top: 1.5em;
  }
}

.profile-page--aside-controls {
  margin-top: 25px;

  @media $small-mobile {
    text-align: center;
  }
}

.profile-page--settings-button {
  @extend .btn, .gray-bordered-btn, .rounded-btn;
  line-height: 40px;
  height: 43px;
  padding: 0 25px;
}

.profile-page--aside-checkbox,
.profile-page--sorting-checkbox {
  padding-top: 0;
  padding-left: 20px;
  font-size: 1.6rem;
  line-height: 1.5;

  @media $small-mobile {
    font-size: 1.4rem;
  }

  &:before {
    width: 12px; height: @width;
    background: #d9dfe7;
    border: none;
    top: .45em;
  }

  &.is-checked:before {
    background: $blue;
  }
}

.profile-page--subcaption {
  font-size: 2.4rem;
  margin-bottom: 20px;
  float: left;

  @media $mobile {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 0;
    float: none;
  }
}

.profile-page--sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 25px;

  .selectric-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  .selectric {
    border-radius: 30px;

    .label {
      font-size: 1.2rem;
      color: #828282;
    }

    .button:before {
      border-color: #828282;
    }
  }

  .selectric-items li {
    font-size: 1.2rem;
  }

  .item + .item {
    margin-left: 10px;
  }

  .item_select {
    color: #828282;
    margin-top: 5px;
  }
}

.profile-page--sorting-group {
  display: flex;
  align-items: center;
}

.item-basket-params {
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.profile-page--sorting-group + .profile-page--sorting-group {
  margin-left: 20px;
}

.profile-page--sorting-label {
  color: #000;
  margin-right: 10px;
}

.profile-page--sorting-radio {
  color: #828282;

  &:not(.is-checked) {
    text-decoration: underline;
    cursor: pointer;
  }
}

.order-history-table {
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);

  .pic-col {
    width: 1%;
    padding-right: 15px;

    a {
      display: block;
      width: 100px;
      position: relative;
    }

    .hover-pic {
      overflow: hidden;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      max-width: 0;
      width: 294px;
      transition: all .25s ease;

      @media $handheld {
        display: none;
      }
    }

    &:hover .hover-pic {
      max-width: 294px;
      z-index: 10;
    }
  }

  .discount-value {
    display: inline-block;
  }

  .discount-percent {
    border-top: 1px solid #ccc;
    padding-top: 5px;
    margin-top: 5px;
  }

  .order-history-item & {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  th {
    font-weight: normal;
    font-size: 1.3rem;
    color: #828282;
    text-align: left;
    padding-bottom: 7px;
    border-bottom: 2px solid #e0e0e0;

    @media $mobile {
      display: none;
    }
  }

  td {
    padding: 22px 20px 17px 0;
    font-size: 1.6rem;
    color: #000;

    @media $less-than-wrapper {
      padding-right: 10px;
    }

    @media $mobile {
      display: block;
      padding-left: 50%;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 0;
      position: relative;

      &:before {
        content: attr(data-caption);
        font-size: 1.6rem;
        color: #828282;
        position: absolute;
        left: 0; top: 10px;
      }
    }
  }

  @media $mobile {
    tr {
      display: flex;
      flex-direction: column;
      position: relative;

      td:first-child {
        order: 2;

        + td {
          order: 1;
          padding-left: 0;
          padding-bottom: 10px;
          text-align: center;

          ~ td {
            order: 3;
          }
        }
      }
    }

    tr + tr {
      margin-top: 30px;
    }

    tr:not(:first-child) + tr {
      border-top: 1px solid #ccc;
      margin-top: 20px;
      padding-top: 20px;

      .pic-col {
        top: 45px;
      }
    }

    .order-history-item & {
      tr:first-child + tr {
        margin-top: 0;
      }

      tr td:first-child + td {
        padding-left: 50%;
        text-align: left;
        min-height: 60px;
      }
    }

    .pic-col {
      position: absolute;
      left: 0; top: 25px;
      padding-left: 0;

      img {
        max-width: 46px;
        max-height: 46px;
      }
    }
  }
}

@media $not-mobile {
  .order-history-table_lined {
    th + th,
    td + td {
      border-left: 1px solid #e0e0e0;
      padding-left: 15px;
    }

    td {
      border-top: 1px solid #e0e0e0;
    }

    .order-history-table--total-row {
      td {
        border: none;
      }

      .order-history-table--left-border {
        border-left: 1px solid #e0e0e0;
      }
    }
  }
}

.order-history-table--number {
  @extend .btn, .rounded-btn, .blue-btn;
  vertical-align: middle;
  line-height: 43px;
  padding: 0 25px;
  box-shadow: 0 4px 20px rgba(#000, .25);
  font-size: 1.6rem;
}


.profile-page--attention-block {
  display: inline-block;
  padding-left: 33px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.8rem;
  color: red;
  margin-left: 30px;
  position: relative;
  float: left;

  @media $mobile {
    float: none;
  }

  @media $small-mobile {
    font-size: 1.4rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0; top: 50%;
    transform: translateY(-50%);
    width: 23px; height: @width;
    border-radius: 50%;
    background: #d9dfe7 url(/i/attention-icon-black.svg) no-repeat 50%;
  }
}

.on-desktop {
  @media $mobile {
    display:none;
  }
}

.on-mobile {
  display: none

  @media $mobile {
    display: block;
  }
}


.profile-page--parking-teaser {
  box-sizing: border-box;
  background: #d9dfe7 url(/i/parking-icon.svg) no-repeat 25px 50%;
  margin: 20px auto;

  @media $not-small-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8rem;
    line-height: 84px;
    padding: 0 5% 0 100px;
  }

  @media $small-mobile {
    font-size: 1.4rem;
    padding: 15px 10px 15px 65px;
    background-position: 18px 23px;
    background-size: 35px auto;
  }

  p {
    @media $not-small-mobile {
      line-height: 1.2;
    }

    @media $small-mobile {
      line-height: 1.35;
    }
  }
}

.profile-page--parking-teaser-phone {
  white-space: nowrap;

  @media $not-small-mobile {
    font-size: 2.4rem;
    margin-left: 30px;
  }

  @media $small-mobile {
    display: inline-block;
    font-size: 2rem;
    margin-top: 15px;
  }
}


.profile-page--order-header {
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 35px;

  @media $not-mobile {
    padding: 25px 30px;
    box-shadow: 0 4px 20px rgba(#000, .1);
  }

  @media $tablet {
    margin-top: 70px;
  }

  @media $mobile {
    margin-top: 80px;
    margin-bottom: 45px;
    flex-direction: column;
  }

  .item + .item {
    margin-top: 20px;
  }

  .item {
    display: flex;
    align-items: flex-start;

    dt {
      width: 125px;
      color: #828282;

      @media $mobile {
        flex-shrink: 0;
      }
    }

    dd {
      color: #000;
    }
  }
}

.profile-page__manager {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &-body {
    display: flex;
    flex-direction: column;
  }

  &-tel {
    color: $dark-blue;
    position: relative;
    margin-left: 7px;

    &::after {
      content: '';
      position: absolute;
      right: -25px;
      top: 0;
      background url('/i/manager-whatsapp-icon.svg') 0 0/cover no-repeat;
      width: 20px;
      height: 20px;
    }
  }
}

.profile-page--order-header_contact {
  box-shadow: none;
  margin-bottom: 0;
  position: relative;
  top: -76px;
  margin-bottom: @top;

  + .profile-page--order-header {
    margin-top: 0;
  }

  @media $small-handheld {
    top: -30px;
    margin-bottom: @top;
  }

  @media $small-mobile {
    top: 0;
    margin-bottom: 20px;
  }

  @media $not-small-mobile {
    .item dt {
      width: auto;
      margin-right: 5px;
    }
  }

  @media $not-small-handheld {
    .profile-page--back-link + & {
      .profile-page--order-header-group:first-child > * {
        padding-left: 180px;
      }
    }
  }
}

.profile-page--order-header-group {
  flex: 1;

  @media $mobile {
    & + & {
      margin-top: 20px;
    }
  }

  &.profile-page--order-header-group-phone {
    flex: 0.7;
  }

  .item {
    @media $not-small-mobile {
      padding-left: 90px;

      @media $handheld {
        padding-left: 60px;
      }
    }
  }

  .profile-page--order-header &:last-child {
    @media $handheld {
      flex: 0.5;
    }
  }
}


.order-history-item + .order-history-item{
  margin-top: 50px;
}

.order-history-item .order-history-table {
  td {
    font-size: 1.4rem;
    min-height: 1em;
    vertical-align: top;

    @media $mobile {
      font-size: 1.6rem;
    }

  }

  /* img {
    max-width: 46px;
  } */
}

.order-history-item--caption {
  font-size: 1.8rem;
  color: $blue;
  margin-bottom: 15px;
}

.order-history-table--total-row {
  background: #f4f6f8;

  td {
    font-size: 1.6rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @media $mobile {
    margin-top: 10px !important;
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;

    td {
      box-sizing: border-box;
      width: 50%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.order-history-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media $not-mobile {
    margin-top: 40px;
  }

  @media $mobile {
    margin: 25px 25px 0 0;
    flex-direction: column;
  }

  .item + .item {
    @media $mobile {
      margin-top: 25px;
    }
  }

  .item {
    @extend .btn;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 30%;
    height: 60px;
    border: 1px solid #97CDFA;
    border-radius: 10px;
    line-height: 60px;
    font-size: 1.6rem;
    text-align: left;
  
    @media $mobile {
      display: block;
      width: 60%;
      height: 40px;
      padding-left: 20px;
      margin-left: auto;
      text-align: left;
      line-height: 40px;
    }
  
    &::before {
      content: '';
      width: 70px;
      height: 40px;
      margin: 10px
      background-repeat: no-repeat;
      background-size: contain;

      @media $mobile {
        position: absolute;
        margin: 0;
      }
    }
  
    &.sbp {
      &::before {
        width: 80px;
        height: 50px;
        background-image: url(/i/sbp.svg);

        @media $mobile {
          left: -100px;
        }
      }
    }
  
    &.card {
      &::before {
        background-image: url(/i/card.svg);

        @media $mobile {
          left: -90px;
        }
      }
    }
  
    &.invoice {
      &::before {
        width: 40px;
        background-image: url(/i/invoice.svg);

        @media $mobile {
          left: -73px;
        }
      }
    }
  
    &:hover {
      background: linear-gradient(0deg, rgba(215,237,255,1) 0%, rgba(238,247,255,1) 100%);
      border: none;
      height: 62px;
      width: calc(30% + 2px);
      line-height: 62px;
      -webkit-text-stroke: 0.3px #000;
  
      @media $mobile {
        height: 42px;
        line-height: 42px;
        width: calc(60% + 2px);
      }
    }
  }
}

.profile-page--aside_chat {
  background: #edeff1;
  /* padding: 10px 0 30px; */
  padding: 20px 0 50px;
  position: relative;
  top: auto; right: auto;
  width: auto;
  margin-top: 30px;

  @media $handheld {
    margin-top: 50px;
  }

  @media $mobile {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .chat--caption {
    position: relative;
    font-size: 2.4rem;
    text-align: center;
    color: #000;
    margin-bottom: 20px;

    @media $mobile {
      margin-bottom: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 1.8rem;
      text-align: left;

      &:after {
        content: '';
        display: block;
        width: 8px; height: @width;
        box-sizing: border-box;
        border-bottom: 2px solid #000;
        border-left: 2px solid #000;
        position: absolute;
        right: 20px; top: 50%;
        transform: rotate(-45deg) translate(50%, -50%);
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%; height: 1px;
      background: #828282;
      position: absolute;
      left: 0; top: 60%;
      transform: translateY(-50%);

      @media $mobile {
        display: none;
      }
    }

    span {
      display: inline-block;
      background: #edeff1;
      padding: 0 15px;
      position: relative;
    }
  }

  .chat--counter {
    width: 18px; height: @width;
    line-height: @height;
    text-align: center;
    border-radius: 50%;
    background: $blue;
    color: $white;
    font-size: 1.4rem;
    font-style: normal;
    position: absolute;
    right: -4px; top: -6px;

    @media $not-mobile {
      display: none;
    }
  }

  .chat--content {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;

    @extend .wrapper;

    /* @media $not-mobile {
      max-height: 620px;
    } */

    @media $mobile {
      overflow: hidden;
      transition: all .25s ease;
    }
  }

  @media $mobile {
    &:not(.is-expanded) {
      .chat--content {
        max-height: 0;
        opacity: 0;
      }
    }

    &.is-expanded {
      .chat--content {
        padding-bottom: 30px;
      }

      .chat--caption:after {
        border: 2px solid #000;
        border-bottom: none;
        border-left: none;
        margin-top: 2px;
      }
    }
  }

  .chat--body {
    overflow-y: auto;
    position: relative;
    /* min-height: 100px; */
  }

  .chat--respondent {
    font-size: 1.3rem;
    color: #828282;
    margin-bottom: 7px;

    @media $mobile {
      font-size: 1.2rem;
    }
  }

  .chat--respondent-pic {
    display: inline-block;
    vertical-align: middle;
    width: 46px; height: @width;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }

  .chat-date-section--caption {
    font-size: 1.2rem;
    margin-bottom: .5em;
  }

  .item-wrapper + .chat-date-section--caption {
    margin-top: 1em;
  }

  .item {
    padding: 10px 15px 35px;
    border-radius: 20px;
    color: #000;
    position: relative;
    font-size: 1.6rem;
  }

  .item-wrapper + .item-wrapper {
    margin-top: 18px;
  }

  .item--text {
    white-space: pre-line;
  }

  .item_answer {
    background: #d9dfe7;
    margin-right: 20%;
  }

  .item_user-message {
    background: $blue;
    margin-left: 20%;
  }

  .item--time {
    position: absolute;
    right: 15px; bottom: 10px;
    color: #828282;
    font-size: 1.3rem;
  }

  .chat--answer {
    margin-top: 20px;
  }

  .form-field {
    position: relative;

    @media $not-mobile {
      margin-left: 20%;
    }
  }
  
  .form-field + .form-field {
    margin-top: 10px;
  }

  .chat--input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 20px;
    font-size: 1.6rem;
    padding: 10px 15px;
    max-height: 200px;
    background: transparent;
    margin-bottom: 5px;
  }

  .form-submit {
    text-align: center;
    margin-top: 25px;
  }

  .submit-buttons {
    @extend .btn, .blue-btn, .rounded-btn;
    height: 43px;
    line-height: 40px;
    font-size: 2.2rem;
    padding: 0 25px;
    box-shadow: 0 4px 20px rgba(#000, .25);
  }

  .selectric {
    border-radius: 20px;
    background: transparent;

    .label {
      font-size: 1.6rem;
      margin-left: 15px;
    }
  }

  .selectric-items li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fileinput {
    @extend .btn, .rounded-btn;
    bordered-btn(1px, #bdbdbd);
    line-height: 40px;
    padding: 0 15px;
    color: #828282;
    position: relative;

    &:hover {
      background: #bdbdbd;
      color: #000;
    }

    input {
      display: none;
    }
  }

  .form-field_fileinput {
    position: relative;
  }

  .fileinput-filename {
    position: absolute;
    right: 0; top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 155px);
    font-size: 1.4rem;
    color: #828282;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .form-field_hint {
    font-size: 1.2rem;
  }

  .form-field_hint-padding {
    padding-right: 22px;
  }

  .chat--hint {
    display: block;
    width: 21px; height: 21px;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    position: absolute;
    right: -8px; top: 50%
    transform: translateY(-50%);
    background: url(/i/info-icon-gray.svg) no-repeat 50%;
    cursor: pointer;
  }

  .form-errors,
  .errorMessage {
    display: none !important;
  }
}


// Help

.order-history-help {
  display: none;
  width: 100%; height: 100%;
  background: rgba($white, .85);
  position: absolute;
  left: 0; top: 0;
  z-index: 9999;

  .order-history-table--number:after {
    content: '';
    display: block;
    width: 55px; height: 81px;
    background: url(/i/help-arrow.svg) no-repeat 50% / contain;
    position: absolute;
    left: 100%; top: 5px;
    margin-left: 5px;
  }
}

.order-history-help--text {
  font-size: 1.8rem;
  position: absolute;
  transform: translateX(-50%);
  text-align: center;

  @media $handheld {
    font-size: 1.6rem;
  }
}

.order-history-help--close {
  @extend .btn;
  bordered-btn(3px, $blue);
  background: transparent;
  line-height: 43px;
  border-radius: 30px;
  padding: 0 25px;
  font-size: 1.6rem;
  margin-top: 10px;

  &:hover {
    background: $white;
  }
}
