.partnership-request-section_custom {
  display: flex;
  align-items: center;
  min-height: 470px;
  padding-top: 10px;
  padding-bottom: 30px;
  box-sizing: border-box;

  p + p {
    margin-top: 2em;
  }
}

.partnership-request-title {
  font-size: 42px;

  @media $mobile {
    font-size: 24px;
  }
}

.partnership-request-section--grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media $notebook-and-less {
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  @media $mobile {
    grid-template-columns: 1fr;
  }
}

.partnership-request-section--grid-item {
  box-shadow: 0px 0px 21px 0px #8181811A;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 30px;

  @media $mobile {
    padding: 5px 20px;
  }
}

.partnership-request-section--grid-item--icon {
  flex: none;

  @media $mobile {
    width: 50px;
    height: 50px;
  }
}

.partnership-request-section--centered-text {
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center;

  @media $small-mobile {
    font-size: 18px;
  }
}

.partnership-request-info-page--caption {
  margin-bottom: 30px !important;
  margin-top: 20px !important;

  @media $mobile {
    margin-bottom: 20px !important;
  }

  h1 {
    font-size: 42px;

    @media $mobile {
      font-size: 24px;
    }
  }
}

.partnership-request-section--form-success {
  font-size: 42px;
  line-height: 46px;
  font-weight: 400;
  text-align: center;

  span {
    display: block;
    margin-bottom: 30px;

    @media $mobile {
      text-align: left;
      width: 80%;
    }
  }

  @media $mobile {
    font-size: 24px;
    line-height: 26px;

    svg {
      width: 150px;
      height: 150px;

      image {
        width: 150px;
        height: 150px;
      }
    }
  }
}

.partnership-request-section--form {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;

  @media $mobile {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .errorMessage {
    margin: 0;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    justify-content: space-between;

    & .form-submit {
      margin: 0;

      button {
        border-radius: 0;
        width: 100%;
        box-shadow: none;
        &:after {
          content: unset;
        }
      }
    }

    &.one-column-form .form-row + .form-row {
      margin: 0;
    }
  }

  .ya-share2-hide-text {
    display: flex;
    gap: 10px;
  }

  & .ya-share2__item_service_vkontakte a:before {
    content: "";
    display: inline-block;
    background: url(/i/img/social/vk-bg.svg) no-repeat;
    background-size: contain;
  }

  & .ya-share2__item_service_telegram a:before {
    //content: url(/i/sm-tg-mini.svg);
    content: "";
    display: inline-block;
    background: url(/i/img/social/tg-bg.svg) no-repeat;
    background-size: contain;
  }

  & .ya-share2__item_service_viber a:before {
    //content: url(/i/sm-vb-mini.svg);
    content: "";
    display: inline-block;
    background: url(/i/img/social/viber-bg.svg) no-repeat;
    background-size: contain;
  }

  & .ya-share2__item_service_whatsapp a:before {
    //content: url(/i/sm-wa-mini.svg);
    content: "";
    display: inline-block;
    background: url(/i/img/social/whatsapp-bg.svg) no-repeat;
    background-size: contain;
  }
}

.partnership-request-section--form-right-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.partnership-request-section--form-left-side {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.partnership-request-section--form-share-and-contact {
  display: flex;
  gap: 15px;
  flex-direction: column-reverse;

  @media $desktop {
    flex-direction: row;
  }
}

.partnership-request-section--form-share {
  flex: 1;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
  height: 80px;
  background: #F7F6F6;
  border: 1px solid #818181;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  text-decoration: none;

  @media $mobile {
    padding: 10px;
  }

  img {
    min-width: 40px;
    min-height: 40px;
    height: 40px;
  }
}

.partnership-request-section--form-image {
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 13/5;
  height: 100%;

  @media $mobile {
    display: none;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.partnership-request-form-success-popup {
  padding: 100px 20px 80px;

  @media $mobile {
    border-radius: 30px;
    padding: 15px;
  }
}
