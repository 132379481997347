.sitemap-out {
  display: flex;
  flex-direction column
  flex-wrap: wrap;
  margin: 50px 0;
}


.sitemap-out > div {
  margin: 0 0 30px 0 !important;
  border-bottom: 1px dashed rgba(79, 79, 79, 0.25);
  padding-bottom: 20px;
}

.sitemap-out > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.sitemap-item-title {
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
}

.sitemap-item-list {
  margin-top: 0 !important;
}

.sitemap-item-list ul {
  display: flex;
  flex-wrap: wrap;
}

.sitemap-item-list ul li {
  padding-left: 0;
  margin: 5px 15px 5px 0 !important;
}

.sitemap-item-list ul li:before {
  display: none;
}

.sitemap-item-list ul li a:hover {
  text-decoration: none !important;
}

.sitemap-item-list ul {
  margin: 0 !important;
}

.sitemap-link {
  display: block;
  width: 100%;
  // text-align: center;
  margin-top: 0.25em;
}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 479px) {

}

.news-page {

}

.news-page .pager li {
  margin: 0 !important;
}

.news-page .pager li:before {
  display: none !important;
}

.news-page-main {
  padding 60px 0
}

.news-items-list {
  margin-bottom 40px;
}

.news-items-list .items {
  display: flex;
  flex-wrap wrap;
}

.news-items-list-item {
  width: calc(100% / 4 - 2px - 20px);
  margin-left 10px;
  margin-right 10px;
  margin-bottom 20px;
  margin-top: 0 !important;
  border 1px solid #828282
}

.item-in {
  padding 25px;
  display: flex;
  flex-direction column;
  justify-content: space-between;
  height: calc(100% - 50px);
}

.name {
  font-size: 24px;
  font-weight bold;
  margin-bottom 15px;
}

.name a {
  text-decoration none;
}

.date {
  margin-bottom 15px;
  font-size: 14px;
  font-weight bold;
}

.notice {
  margin-bottom 15px;
  font-size: 14px;
}

.more {
  text-align right;
}

.item-in a {
  color #676767;
}

.item-in a:hover {
  color: #4f4f4f;
}

.news-page-detail-title {
  font-size: 4.2rem;
  margin 25px 0;
}

.news-page-detail-date {
  margin-bottom: 25px;
}
.form--fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 10px;
    padding: 30px;
    background-color: #F0F8FF;
    gap: 25px;
    @media $small-mobile {
      background-color: #FFFFFF;
      padding: 0;
    }
  }

.form-title {
  margin-bottom: 20px;
  font-size: 23px;
  @media $small-mobile {
    text-align: center;
  }
}
.collections-out {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d2d2d2;
  padding-top: 25px;

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .mobile-select{
    display: none;
    margin-bottom: 15px;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .collections-out-left {
    width: 250px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .category-info{
      padding-right: 25px;
      padding-left: 25px;
      @media screen and (max-width: 767px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .image{
      margin: 0 0 15px 0;
      a {
        max-width: 185px;
        max-height: 90px;
        overflow: hidden;
        display: flex;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 767px) {
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }
  }

  .collections-out-right {
    width: calc(100% - 250px);

    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .collections--list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-top: 0;

      @media screen and (max-width: 767px) {
        display: none;
      }

      .item {
        flex-basis: calc(100% / 6 - 15px);
        width: calc(100% / 6 - 15px);
        margin: 0 15px 15px 0;
        padding: 0;

        @media screen and (max-width: 991px) {
          flex-basis: calc(100% / 3 - 15px);
          width: calc(100% / 3 - 15px);
        }

        .name {
          text-align: center;
          padding-right: 10px;
          a {
            color: #5c5c5c;
            font-size: 20px;
          }
        }

        .image {
          margin-bottom: 15px;
          max-width: 100%;
          max-height: 80px;
          width: 100%;
          height: 100%;
          position: relative;

          a {
            overflow: hidden;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}

  .price_meter_pogonniy{
    font-size: 22px;
    &.basket{
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .visible{
    opacity: 1!important;
    line-height: 1.2;
    .price_meter_pogonniy{
      font-size: 14px;
      margin-top: 5px;
    }
  }


.popup-new {
  padding: 5px 35px 10px;
  width: 800px;
  @media screen and (max-width: 767px) {
    padding: 0 15px 30px;
    max-width: 80%;
    left: 10%;
    top 35px !important;
    border-radius 30px;
  }

  .popup--close{
    width: 45px;
    height: 45px;
    top: 13px;
    right: 15px;
    background inherit;
    border 1px solid #97CDFA;

    &:before{
      width: 30px;
      height 1px;
      background black;
    }

    &:after{
      height: 30px;
      width 1px;
      background black;
    }

    @media screen and (max-width: 767px) {
      top: 22px;
      right: 18px;
      width: 34px;
      height: 34px;
      z-index: 100;
      border 2px solid #97CDFA;

      &:before{
        width: 25px;
        height 2px;
      }
      &:after{
        height: 25px;
        width 2px;
      }
    }
  }

  .popup--group-new-promo{
    margin: 0 -35px 0px -35px;
    text-align: center;
    color: #4f4f4f;
    position: relative;
    width: calc(100% + 70px);
    .promo-text {
      display: inline-block;
      padding: 10px 25px 5px 25px;
      font-size: 2rem;
      background-color: #fff;
      position: relative;
      z-index: 2;
    }
    .promo-line{
      position: absolute;
      left: 0;
      top: 1%;
      bottom: 1%;
      margin: auto 0;
      width: 100%;
      height: 1px;
      background: #b4b4b4;

      @media screen and (max-width: 767px) {
        max-width: 80%;
        left: 10%;
      }
    }
  }

  .promocode-popup--footer {
    padding-top: 0;
    margin-top: 15px;
    color: #8d8d8d;
    font-size: 18px;
    line-height 1.4;
    .img-out{
      width calc(100% + 70px);
      margin-left: -35px;
      margin-right: -35px;
      @media screen and (max-width: 767px) {
        width: inherit;
        margin-left: 0;
        margin-right: 0;
      }
    }
    img {
      margin-left: 0px;
      margin-right: 0px;
      max-width: 100%;
      width 100%;
      margin-bottom: 10px;
    }
  }
  .text-out{
    font-size: 1.6rem;
    line-height: 1.2;
  }
}

.promocode-popup{
  .popup--text-new{
    margin-bottom: 10px;
    @media screen and (max-width: 479px) {
      margin-bottom: 15px;
    }
    .popap--text--top{
      color: #4f4f4f;
      font-size: 3rem;
      margin-bottom: 5px;
    }
    .popap--text--bottom{
      color: #4f4f4f;
      line-height: 1.4;
      font-size: 2rem;
    }
  }
}

.popup--flex-group-new{
  flex-direction: column;
}
    .popup--flex-group-top{
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
      .popup--flex-group-top-left{
        width: calc(50% + 25px);
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 0px;
        }
      }
      .popup--flex-group-top-right{
        width: calc(50% - 25px);
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .promocode-popup--icons{
          margin-left: 0;
        }
          .item{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            & + .item {
              margin-top: 10px;
            }
            .img{
              min-width 120px;
              text-align: center;
              img{
                margin-bottom: 0;
                max-height: 95px;
              }
            }
            .item--text{
              text-align: left;
              padding-right: 25px;
              color: #525252;
              font-size: 1.6rem;
              span{
                display: block;
                margin-bottom: 10px;
                color: #000000;
                font-size: 18px;
              }
            }
          }
      }
    }
    .popup--flex-group-bottom{
      .popup--flex-group-bottom-title{
        color: #000000;
        font-size: 36px;
        line-height 1.4;
        text-align: center;
        margin-bottom: 25px;
      }
      .promocode-popup--features{
        margin: 0;
        font-size: 1.8rem;
        li {
          padding-left: 75px;
          background: url('../../../i/transparent-check-green-tick-11.png') no-repeat;
          background-position: 25px top;

          & + li {
            margin-top: .1em;
          }

          @media screen and (max-width: 767px) {
            padding-left: 50px;
            background-position: 0px top;
          }
        }
      }
    }
    .popup--group-new{
      position: relative;
      .popup--group-new-form{
        //background-color: #e9f4ff;
        padding: 15px 25px 0px 0px;
        @media screen and (max-width: 767px) {
          margin-bottom: 50px;
          padding: 15px 0 0 0;
          form{
            max-width: 100%;
          }
        }
        @media screen and (max-width: 479px) {
          margin-bottom: 0;
        }
        .inp, .textarea{
          border: 1px solid #d7d7d7;
          line-height: 1.4;
          padding: 0.13em 15px;
          &::placeholder{
            color: #919191;
            font-size: 1.8rem;
          }
        }

        .one-column-form {
          .form-row + .form-row {
            margin-top: 13px;
          }
        }

        .form-submit {
          .submit-button{
            font-size: 1.9rem;
            padding: 0px 80px 5px 35px;
            height: 35px;
            box-shadow: 0 0 24px rgba(0, 0, 0, 0.17);
            line-height: 27px;
            &::after{
              width: 35px;
              height: 24px;
              background: url('../../../i/right-arrow-icon.png') no-repeat 50%;
              background-size: auto 15px;
            }
            @media screen and (max-width: 767px) {
              font-size: 20px;
              line-height: 1;
              height: 55px;
            }
          }
        }
      }
      .form-field {
        .errorMessage {
          margin-top: 0px;
        }
      }
    }

    .promocode-popup .promocode-form{
      margin-bottom: 0;
    }

.roistat-promo-wrap {
  opacity: 0 !important;  
}
.sale-group {
  .product--price {
    color: red;
    font-size: 2.4rem;
  }
}


.sale_style_menu_item {
  color: #000!important;
}

.tenevoy {
  width: 76px !important;
}

.remains_style_menu_item {
  font-weight: bold;
}

.old-price-sale-product{
  text-decoration: line-through;
  color: #aaaaaa;
    @media $small-mobile {
      text-decoration: none;
      color: #000000;
      font-size: 14px;
      margin-left: 5px;
    }
}

.price-sale-product{
  color: #f97383;
}

.price-sale-product-percent {
  background-color: #f97383;
  color: #fff;
  border-radius: 5px;
  padding: 0 2px;
  position: absolute;
  top: 150%;
  right: 50%;
  width: 44px;
  text-align: center;
  min-width:40px;
  transform: translate(50%);
    @media $small-mobile {
      top: 0;
      right:24px;
      
    }
}

.style-new a{
  -webkit-box-shadow: 0px 3px 0px 1px #F58897;
  box-shadow: 0px 3px 0px 1px #F58897;
  border:1px solid #F58897;
  background #fff;
  color: #000;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  border-radius: 30px;
  padding: 0 10px 0 10px;
  font-size: 1.6rem;
  height: 46px;
  text-align: left;
  flex: 1 1 0;
  justify-content: space-evenly;
  gap: 5px;
  //f24b4c

  &.blue-border {
    -webkit-box-shadow: 0px 3px 0px 1px #97CDFA;
    box-shadow: 0px 3px 0px 1px #97CDFA;
    border:1px solid #97CDFA;

    &:hover {
      background: #97CDFA;
    }
  }
}
.style-new a:before{
  color: #f24b4c;
}

.style-new a:hover{
  background: #ff92a1;
}

.pre-header--flex-container {
  min-height: 100px!important;
}
  .pre-header--content{
    font-size: 1.8rem!important;
  }

@media screen and (max-width: 991px) {
  .news-items-list-item {
    width: calc(100% / 3 - 2px - 20px);
  }

  .item-in {
    padding 15px;
    height: calc(100% - 30px);
  }
}

@media screen and (max-width: 767px) {
  .news-page-detail-title h1 {
    font-size: 2.8rem !important;
  }

  .news-items-list-item {
    width: calc(100% / 2 - 2px - 20px);
  }

  .date {
    font-size: 14px;
  }

  .news-page-detail-date {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {
  .news-items-list-item {
    width: calc(100% / 1 - 2px - 20px);
  }
}

@media only screen and (min-width: 701px){
  .order-history-actions {
    margin-top: 40px;
    display: flex;
    flex-flow: row wrap;
    /*align-items: center;*/
    align-items: flex-start;
    justify-content: space-between;
  }

  .order-history-actions > .item{
    margin-left: 0;
    margin-bottom: 20px;
  }
  .order-history-actions .item + .item{
    margin-left: 0;
  }
}

.arch {
  -webkit-font-smoothing: subpixel-antialiased;
  font: 10px/1.2 'Didact Gothic',sans-serif;
  font-weight: 300;
  margin: 0;
  border: 0;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  background-color: #97cdfa;
  color: #fff;
  border-radius: 30px;
  line-height: 32px;
  height: 35px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.25);
  font-size: 1.5rem;
  padding: 0 20px 0 20px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translate3d(-50%,0,0);
  z-index: 5;
  transition: all .25s ease;
  opacity: 1;
  right: 0;
  max-width: 250px;
  width: 100%;
}

.arch--collection {
  max-width: 100px;
  top: 80%;
  padding: 0 10px 0 10px;
  height: 20px;
  line-height: 18px;
}

@media (max-width: 1200px) {
  .arch--collection {
    max-width: 50px;
  }
}

.mores {
  display: flex;
  flex-wrap: wrap;

  &:not(:empty) {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  div:not(:last-child) {
    margin-right: 15px;
  }

  div a {
    color: #338cd6;
  }

  @media $small-mobile {
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: fit-content;
    margin-top: 22px !important;
    padding-bottom: 24px;
    background-color: #EDF7FF;
    border-radius: 10px;
    font-size: 1.8rem;
    text-align: center;

    .mores-header {
      color: #000;
      margin-bottom: 2px;
      line-height: 36px;
    }

    &:not(:has(a)) {
      display: none;
    }

    div {
      margin: 0 !important;
    }
  
    div a {
      color: #5CA5E2;
    }
  }
}

.header {
  box-sizing: initial;
  -webkit-box-sizing: initial;
}

.avail-count {
  font-size: 1.6rem;
}

.order-history-actions__qr-note {
  margin-top: 10px;
  font-size: 18px;
  color: gray;

  a {
    text-decoration: underline;
  }
  a:hover {
    cursor: pointer;
  }
}

.main-rotator.only-desktop, .main-rotator.not-desktop {
  overflow-y: hidden;
}


#additional-search{
  position: absolute;
  right: 0px;
  top: 105%;
  max-width: 275px;
  font-size: 14px;
  border: 1px solid #97cdfa;
  background: #fff;
  padding: 8px 15px;
  border-radius: 15px;
  display none;
}

@media (max-width: 1200px) {
  #additional-search {
    display: none;
  }
}

/* Стили для режима увеличения */
  .gallery-popup-content.zoomed {
    width: 95%;
    max-width: 1400px;
    height: 80vh;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  /* Стили для увеличенного контейнера изображения */
  .gallery-popup-image-container.zoomed {
    height: 80%;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-zoomed {
    display: none;
  }

  .thumbnails-container-zoomed {
    margin: 20px auto !important;
  }

  .content-zoomed {
    height: 80vh;
  }

  .wrapper-up-zoomed {
    right: 0;
    margin: 20px 0 !important;
    position: absolute !important;
  }

  .image-container-zoomed {
    transition: height 0.4s ease, padding 0.4s ease;
    padding-top: 0 !important;
    height: 75% !important;
  }

  /* Стили для галереи с popup */
  .gallery-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }

  .gallery-popup-wrapper-up {
    position: relative;
    padding: 0 35px;
    margin: 40px 0;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .wrapper-up-zoom {
    position: absolute;
    margin: 10px 0;
    top: 0;
    right: 0;
  }

  .gallery-popup-title {
    color: white;
    font-size: 22px;
    width: 50%;
  }

  .gallery-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .gallery-popup-content {
    height: 71vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    background-color: #3E424A;
    border-radius: 10px;
    padding: 20px;
    z-index: 10001;
  }

  .gallery-popup-wrapper-button {
    display: flex;
  }

  .gallery-popup-big {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .gallery-popup-close {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    color: white;
    font-size: 70px;
    cursor: pointer;
    z-index: 10002;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: opacity 0.2s;
  }

  .gallery-popup-close:hover {
    opacity: 1;
  }

  .gallery-popup-image-container {
    transition: height 0.4s ease, padding 0.4s ease;
    width: 100%;
    height: 33vh;
    padding-top: 35px;
    margin: 0px;
    text-align: center;
  }

  .gallery-popup-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Новые стили для контейнера миниатюр */
  .gallery-popup-thumbnails-container {
    width: 100%;
    max-width: 400px; /* Ширина для 4 миниатюр с отступами */
    margin: 35px auto;
    overflow: hidden;
    position: relative;
  }

  .gallery-popup-thumbnails {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE и Edge */
    gap: 31px;
    padding-bottom: 20px; /* Пространство для индикатора активной миниатюры */
    cursor: grab;
  }

  /* Скрываем стандартный скроллбар */
  .gallery-popup-thumbnails::-webkit-scrollbar {
    display: none;
  }

  /* Стиль при активном драге */
  .gallery-popup-thumbnails.active {
    cursor: grabbing;
  }

  .gallery-popup-thumbnail {
    flex: 0 0 80px;
    width: 70px;
    height: 70px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.2s;
  }

  .gallery-popup-thumbnail.active {
    position: relative;
  }

  .gallery-popup-thumbnail.active::after {
    position: absolute;
    background-color: #ffffff;
    width: 60%;
    content: "";
    height: 2px;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Адаптивные стили */
  @media (max-width: 768px) {
    .gallery-popup-content {
      width: 100%;
      padding: 0;
      border-radius: 0;
    }

    .gallery-popup-thumbnails-container {
      max-width: 310px; /* Меньше на мобильных */
    }

    .gallery-popup-thumbnail {
      flex: 0 0 60px;
      width: 70px;
      height: 70px;
    }
  }

  @media (max-width: 480px) {
    .gallery-popup-thumbnails-container {
      transition: opacity 0.3s ease;
      max-width: 310px;
    }

    .gallery-popup-thumbnail {
      flex: 0 0 50px;
      width: 70px;
      height: 70px;
    }
  }